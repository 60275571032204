import { useMutation, useQueryClient } from '@tanstack/react-query';
import { documentsApi } from '../api/documents';
import { ReactQueryKeys } from './reactQueryKeys';

export const useDeleteFile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: number }) =>
      documentsApi.delete({
        id,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.Docs]);
    },
  });
};
