import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';

const query = graphql(/* GraphQL */ `
  query Projects {
    projects(
      filter: { type: { equalTo: "PROJECT" }, isActive: { equalTo: true } }
      orderBy: [CREATED_AT_DESC]
    ) {
      id
      isActive
      address
      title
      status
      description
      projectSize
      projectValue
      trades
      type
      stageId
      stage {
        type
      }
      imageUrl
      streetViewUrl
      projectManager {
        id
        firstName
        lastName
        avatarUrl
        email
        phone
      }

      blueprint {
        blueprintProjectStages {
          position
          projectStage {
            id
            name
            type
            description
          }
        }
      }

      actionsByProjectId {
        id
        uuid
        createdAt
        title
        description
        status
        dueDate
        completedAt
        project {
          id
          address
        }

        projectStageId

        projectStage {
          id
        }

        files {
          id
          name
          downloadUrl
          metaData
        }
      }

      tasksByProjectIdConnection(filter: { isArchived: { equalTo: false } }) {
        nodes {
          id
          title
          address
          status
          projectStageId
          project {
            type
            address
            geoLocation
            accountType
          }
          assignee {
            id
            firstName
            lastName
            avatarUrl
            email
            phone
          }
          taskVisits {
            id
            createdAt
            startDate
            endDate
            isCompleted
          }
        }
      }
    }
  }
`);

export const useProjects = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.Projects],
    queryFn: async () => {
      const response = await graphqlRequest(query);

      if (!response.projects) {
        return [];
      }

      return response.projects;
    },
  });
};

export type Project = Exclude<
  ReturnType<typeof useProjects>['data'],
  undefined
>[number];

export type ProjectTask = Exclude<
  Exclude<
    ReturnType<typeof useProjects>['data'],
    undefined
  >[number]['tasksByProjectIdConnection']['nodes'],
  undefined
>[number];

export type ProjectAction = Exclude<
  Exclude<
    ReturnType<typeof useProjects>['data'],
    undefined
  >[number]['actionsByProjectId'][number],
  null
>;
