import styled from 'styled-components';
import { Panel } from '../../ui/Panel';

export const Container = styled(Panel)`
  padding: 0;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: 16px;

  @media (min-width: 768px) {
    padding: 16px 24px 24px;
  }
`;

export const Header = styled.div`
  padding: 24px;
  background-color: #f0f5ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  overflow: auto;
`;

export const HeaderStatus = styled.div<{ isCompleted: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${(props) => (props.isCompleted ? '#009A47' : '#828D9A')};
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const AddressLink = styled.a`
  display: inline-flex;
  color: #235dff;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: #235dff;
  }
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Chips = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const Person = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContactPhoto = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 8px;
`;

export const ContactPhotoPlaceholder = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 8px;
  background-color: #f7f7fc;
  color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const ContactJob = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const AppointemntsContainer = styled.div`
  margin-top: 32px;
`;

export const ListTitle = styled.div`
  color: #1d1d35;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #dfdfe8;
  overflow: hidden;

  > *:not(:last-child) {
    border-bottom: 1px solid #dfdfe8;
  }
`;
