import React, { useCallback, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useUpdateEffect } from '@react-hookz/web';

import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { useControllerWithValidation } from '../../useControllerWithValidation';
import { Input } from '../../../../ui/Input';
import { FileData } from '../../../../../api/documents';
import { ButtonVariant, IconButton } from '../../../../ui/Button';
import { FileDropZone } from '../../../FileDropZone';
import {
  FileList,
  FileName,
  FileRow,
  FileRowLeft,
  FileRowRight,
  Thumbnail,
  ThumbnailContainer,
} from './styled';
import { FileIcon } from '../../../../ui/icons/File';
import { formatFileSize } from '../../../../../features/Docs/helpers';
import { TrashIcon } from '../../../../ui/icons/Trash';
import { useUploadFile } from '../../../../../apiHooks/useUploadFile';
import { showToast } from '../../../Toast';
import { useDeleteFile } from '../../../../../apiHooks/useDeleteFile';
import { Loader } from '../../../../ui/Loader';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<React.ComponentProps<typeof Input>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {}

enum UploadStatus {
  New = 'New',
  Uploading = 'Uploading',
  Success = 'Success',
  Error = 'Error',
}

type FileItem = {
  file: File;
  status: UploadStatus;
  doc: {
    id: number;
    name: string | null;
    metaData: { size: number; thumbnailUrl: string };
  } | null;
};

const isPreviewable = (file: File) => {
  const fileType = file.type;
  return fileType.startsWith('image/') || fileType.startsWith('video/');
};

export const FileFieldInner = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
}: Props<TFieldValues, TName>) => {
  const { mutateAsync: uploadFile } = useUploadFile();
  const { mutateAsync: deleteFile } = useDeleteFile();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const [newFiles, setNewFiles] = useState<FileItem[]>(() =>
    (value ?? []).map((doc: FileData) => ({
      file: new File([], doc.name ?? 'unknown'),
      status: UploadStatus.Success,
      doc,
    })),
  );

  const handleAddFiles = useCallback(
    async (files: File[]) => {
      setNewFiles((prev) => [
        ...prev,
        ...files.map((file) => ({
          file,
          status: UploadStatus.Uploading,
          doc: null,
        })),
      ]);

      const uploadedFiles = await Promise.all(
        files.map(async (file) => {
          try {
            const doc = await uploadFile({
              file,
            });

            return { file, status: UploadStatus.Success, doc };
          } catch (error) {
            return { file, status: UploadStatus.Error, doc: null };
          }
        }),
      );

      setNewFiles((prev) =>
        prev
          .map((item) => {
            const uploadedFile = uploadedFiles.find(
              (f) => f.file === item.file,
            );

            if (uploadedFile) {
              return uploadedFile;
            }

            return item;
          })
          .filter((item) => item.doc || item.status === UploadStatus.Uploading),
      );
    },
    [uploadFile],
  );

  useUpdateEffect(() => {
    onChange(newFiles.map((item) => item.doc).filter(Boolean));
  }, [newFiles, onChange]);

  const handleRemove = useCallback(
    (file: FileItem) => async () => {
      try {
        if (file.doc) {
          await deleteFile({ id: file.doc.id });
        }

        setNewFiles((prev) => prev.filter((item) => item.file !== file.file));
      } catch {
        showToast(`${file.file.name} was not removed`, 'error');
      }
    },
    [deleteFile],
  );

  return (
    <FormField
      name={name}
      label={label}
      error={error?.message}
      description={description}
    >
      <FileDropZone onAddFiles={handleAddFiles} padding="0" />

      <FileList>
        {newFiles.length > 0 &&
          newFiles.map((file) => (
            <FileRow
              key={file.doc?.id || `${file.file.name}_${file.file.size}`}
            >
              {file.doc && (
                <>
                  <FileRowLeft>
                    <ThumbnailContainer>
                      {file.doc.metaData?.thumbnailUrl ? (
                        <Thumbnail url={file.doc.metaData.thumbnailUrl} />
                      ) : (
                        <FileIcon size="16px" color="#9C9CAA" />
                      )}
                    </ThumbnailContainer>
                    <FileName>{file.doc.name}</FileName>
                  </FileRowLeft>
                  <FileRowRight>
                    {formatFileSize(file.doc.metaData.size)}
                    <IconButton
                      type="button"
                      variant={ButtonVariant.Flat}
                      onClick={handleRemove(file)}
                    >
                      <TrashIcon size="16px" color="#9C9CAA" />
                    </IconButton>
                  </FileRowRight>
                </>
              )}
              {file.status === UploadStatus.Uploading && (
                <>
                  <FileRowLeft>
                    <ThumbnailContainer>
                      {isPreviewable(file.file) ? (
                        <Thumbnail url={URL.createObjectURL(file.file)} />
                      ) : (
                        <FileIcon size="16px" color="#9C9CAA" />
                      )}
                    </ThumbnailContainer>
                    <FileName>{file.file.name}</FileName>
                  </FileRowLeft>
                  <FileRowRight>
                    {formatFileSize(file.file.size)}
                    <Loader size={24} />
                  </FileRowRight>
                </>
              )}
            </FileRow>
          ))}
      </FileList>
    </FormField>
  );
};

export const FileField = React.memo(FileFieldInner) as typeof FileFieldInner;
