import { apiFetch } from './fetch';

export enum ReviewChannelType {
  Google = 'Google',
  Yelp = 'Yelp',
  BBB = 'BBB',
}

export type ReferralSettings = {
  enabled: boolean;
  promptTitle: string;
  promptDescription: string;
  shareableMessage: string;
  promptImageUrl: string | null;
};

export type CompanyInfo = {
  id: number;
  logoUrl: string;
  name: string;
  settings?: {
    portalReviews?: {
      enabled?: boolean;
      channels?: {
        url: string;
        type: ReviewChannelType;
      }[];
    };

    referral?: ReferralSettings;
    requestButtonEnabled?: boolean;
    pastDueUncompletedEventsVisible?: boolean;
    features?: {
      fleet?: boolean;
      requests?: boolean;
    };
  };
};

export const companyApi = {
  info: (slug: string) =>
    apiFetch<CompanyInfo>({
      url: `/user-service/companies/domain/${slug}`,
      method: 'GET',
    }),
};
