import styled from 'styled-components';

export const FileRow = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  overflow: hidden;
  white-space: nowrap;
`;

export const FileRowLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  min-width: 0;
`;

export const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileRowRight = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

export const Thumbnail = styled.div<{ url: string }>`
  flex-shrink: 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
