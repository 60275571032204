import React, { useCallback, useEffect, useState } from 'react';
import { ModalBody, ModalFooter } from '../../../kit/components/PromiseModal';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  IconButton,
} from '../../../kit/ui/Button';
import {
  AddressLink,
  ArchivedMark,
  FileName,
  FileRow,
  FileRowLeft,
  FileRowRight,
  Label,
  ModalContent,
  ProjectImageContainer,
  ProjectInfo,
  ProjectOption,
  ProjectSelector,
  ProjectTitle,
  Thumbnail,
  ThumbnailContainer,
} from './styled';
import { FileDropZone } from '../../../kit/components/FileDropZone';
import { useUploadFile } from '../../../apiHooks/useUploadFile';
import { FileIcon } from '../../../kit/ui/icons/File';
import { TrashIcon } from '../../../kit/ui/icons/Trash';
import { formatFileSize } from '../helpers';
import { useMe } from '../../../apiHooks/useMe';
import { showToast } from '../../../kit/components/Toast';
import { useProjects } from '../../../apiHooks/useProjects';
import { Select, SelectVariant } from '../../../kit/ui/Select';
import { MapPinIcon } from '../../../kit/ui/icons/MapPin';
import { getGeoLink } from '../../../utils/getGeoLink';
import { ProjectImage } from '../../../kit/components/ProjectImage';

const isPreviewable = (file: File) => {
  const fileType = file.type;
  return fileType.startsWith('image/') || fileType.startsWith('video/');
};

interface Props {
  projectId?: number;
  stageId?: number | null;
  onClose: () => void;
}

export const Upload = ({ projectId, stageId, onClose }: Props) => {
  const { mutateAsync: uploadFile } = useUploadFile();
  const [newFiles, setNewFiles] = useState<File[]>([]);

  const { data: me } = useMe();

  const { data: projects = [] } = useProjects();

  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    projectId ?? null,
  );

  useEffect(() => {
    if (projects.length > 0) {
      setSelectedProjectId((prev) => (!prev ? projects[0].id : prev));
    }
  }, [projects]);

  const clientId = me?.accountId;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddFiles = useCallback((files: File[]) => {
    setNewFiles((prev) => [...prev, ...files]);
  }, []);

  const handleRemove = useCallback(
    (file: File) => () => {
      setNewFiles((prev) => prev.filter((f) => f !== file));
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const projectId = selectedProjectId ?? clientId;

    await Promise.all(
      newFiles.map(async (file) => {
        try {
          const uploadedFile = await uploadFile({
            file,
            projectId,
            stageId: stageId ?? undefined,
          });
          return uploadedFile;
        } catch (error) {
          showToast(`${file.name} was not uploaded`, 'error');
        }
      }),
    );

    setIsSubmitting(false);

    onClose();
  }, [
    newFiles,
    onClose,
    uploadFile,
    isSubmitting,
    clientId,
    selectedProjectId,
    stageId,
  ]);

  return (
    <>
      <ModalBody width="750px">
        <ModalContent>
          {projects.length > 1 && (
            <ProjectSelector>
              <Label>Upload files to:</Label>
              <Select
                value={selectedProjectId ?? null}
                onChange={setSelectedProjectId}
                getOptionLabel={(option) => option.title ?? ''}
                getOptionValue={(option) => option.id}
                variant={SelectVariant.FlatOnDesktop}
                options={projects}
                placeholder="Select Project"
                isClearable={false}
                renderOption={(project) => (
                  <ProjectOption isActive={Boolean(project.isActive)}>
                    <ProjectImageContainer>
                      <ProjectImage
                        width={48}
                        height={48}
                        imageUrl={project.imageUrl || project.streetViewUrl}
                      />
                      {!project.isActive && (
                        <ArchivedMark>Archived</ArchivedMark>
                      )}
                    </ProjectImageContainer>
                    <ProjectInfo>
                      <ProjectTitle>{project.title}</ProjectTitle>
                      {Boolean(project.address?.[0]) && (
                        <AddressLink
                          onClick={(e) => e.preventDefault()}
                          target="_blank"
                          href={getGeoLink(project.address?.[0] ?? '')}
                        >
                          <MapPinIcon size="16px" />
                          <span>{project.address?.[0]}</span>
                        </AddressLink>
                      )}
                    </ProjectInfo>
                  </ProjectOption>
                )}
              />
            </ProjectSelector>
          )}
          <FileDropZone onAddFiles={handleAddFiles} />

          {newFiles.length > 0 &&
            newFiles.map((file, index) => (
              <FileRow key={index}>
                <FileRowLeft>
                  <ThumbnailContainer>
                    {isPreviewable(file) ? (
                      <Thumbnail url={URL.createObjectURL(file)} />
                    ) : (
                      <FileIcon size="16px" color="#9C9CAA" />
                    )}
                  </ThumbnailContainer>
                  <FileName>{file.name}</FileName>
                </FileRowLeft>
                <FileRowRight>
                  {formatFileSize(file.size)}
                  <IconButton
                    variant={ButtonVariant.Flat}
                    onClick={handleRemove(file)}
                  >
                    <TrashIcon size="16px" color="#9C9CAA" />
                  </IconButton>
                </FileRowRight>
              </FileRow>
            ))}
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <Button
          className="cancel"
          size={ButtonSize.Large}
          onClick={onClose}
          variant={ButtonVariant.Secondary}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={newFiles.length === 0 || isSubmitting}
          size={ButtonSize.Large}
          variant={ButtonVariant.Primary}
        >
          Upload
        </Button>
      </ModalFooter>
    </>
  );
};
