import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';

const query = graphql(/* GraphQL */ `
  query Requests {
    projects(
      filter: { type: { equalTo: "DEAL" }, isActive: { equalTo: true } }
      orderBy: [CREATED_AT_DESC]
    ) {
      id
      uid
      type
      address
      title
      isActive
      trades
      dealValue
      dealSize
      createdAt
      description
      requestStatus

      stage {
        type
      }

      salesRep {
        firstName
        lastName
        avatarUrl
      }

      remindersByProjectId(filter: { isAppointment: { equalTo: true } }) {
        id
        title
        createdAt
        dueDate
        isCompleted
        assignee {
          id
          firstName
          lastName
          avatarUrl
          email
          phone
        }
      }
    }
  }
`);

export const useRequests = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.Requests],
    queryFn: async () => {
      const response = await graphqlRequest(query);

      if (!response.projects) {
        return [];
      }

      return response.projects;
    },
  });
};

export type Request = Exclude<
  ReturnType<typeof useRequests>['data'],
  undefined
>[number];
export type RequestAppointemnt = Exclude<
  Request['remindersByProjectId'],
  undefined
>[number];
