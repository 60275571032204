import styled from 'styled-components';
import { Button } from '../../ui/Button';

export const DropZone = styled.div<{ padding?: string }>`
  display: none;

  @media (min-width: 768px) {
    display: block;
    border-radius: 4px;
    padding: ${({ padding }) => (padding ? padding : '16px')};
    cursor: pointer;
    position: relative;
    background-color: #fff;
  }
`;

export const DropZoneInner = styled.div<{ isOver?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 10vh;
  min-height: 80px;
  max-height: 160px;
  border: 1px dashed #dfdfe8;
  transition: border-color 0.15s ease-in-out;
  font-size: ${({ isOver }) => (isOver ? '16px' : '14px')};
  font-weight: 400;

  &:hover {
    border-color: #009688;
  }
`;

export const MobileButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  > * {
    flex: 1;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileButton = styled(Button)`
  background-color: #fff;
  border-color: #009688;
  color: #009688;
  white-space: nowrap;
  text-transform: none;
  height: 37px;

  &:active,
  &:focus {
    background-color: #fff;
    border-color: #009688;
    color: #009688;
  }
`;

export const HightlightedText = styled.span`
  color: #009688;
`;

export const HiddenFileInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
`;

export const Description = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: #828d9a;
    margin: 8px 0;
  }
`;
