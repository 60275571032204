import styled from 'styled-components';
import { ModalBody, ModalFooter } from '../../kit/components/PromiseModal';

export const Body = styled(ModalBody)<{ isInModal: boolean }>`
  background-color: #fff;
  padding-top: 0;
  ${({ isInModal }) =>
    isInModal
      ? `
    @media (min-width: 768px) {
      max-height: calc(90vh - 64px - 64px - 48px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 4px;
        border-radius: 4px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: #f8fafb;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #d2d9e1;
        outline: none;
        border-radius: 4px;
      }
    }
  `
      : `
    padding: 0;
`}
`;

export const Footer = styled(ModalFooter)<{ isInModal: boolean }>`
  padding-top: 0;
  ${({ isInModal }) =>
    !isInModal &&
    `
padding: 0;
`}
`;

export const Group = styled.div`
  padding-top: 16px;
`;

export const GroupName = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Grid = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
`;

export const GridRowFull = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
`;
