import React from 'react';
import { RequestProperty } from '../../apiHooks/useRequestProperties';
import { Control } from 'react-hook-form';
import { FormValues, PropertyType } from './types';
import {
  DateField,
  InputField,
  SelectField,
  FileField,
} from '../../kit/components/Form';

interface Props {
  property: RequestProperty;
  control: Control<FormValues>;
}

export const CustomPropertyField = ({ property, control }: Props) => {
  switch (property.type) {
    case PropertyType.Text:
    case PropertyType.Link: {
      return (
        <InputField
          label={property.name}
          name={`additional.${property.id}`}
          control={control}
        />
      );
    }
    case PropertyType.Numeric:
      return (
        <InputField
          type="number"
          label={property.name}
          name={`additional.${property.id}`}
          control={control}
        />
      );
    case PropertyType.Dropdown: {
      return (
        <SelectField
          label={property.name}
          name={`additional.${property.id}`}
          control={control}
          isMulti={property.multiple}
          options={property.additional?.values || []}
        />
      );
    }
    case PropertyType.Date:
      return (
        <DateField
          label={property.name}
          name={`additional.${property.id}`}
          control={control}
        />
      );
    case PropertyType.File:
      return (
        <FileField
          label={property.name}
          name={`additional.${property.id}`}
          control={control}
        />
      );
    default:
      return null;
  }
};
