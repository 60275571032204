import React from 'react';
import { useMe } from '../../apiHooks/useMe';
import { H2 } from '../../kit/ui/Heading';
import { Container, Content } from './styled';
import { Reviews } from './Reviews';
import { useProjects } from '../../apiHooks/useProjects';
import { AppointmentMap } from './AppointmentMap';
import { ReferralWidget } from '../Referral';
import { ActiveProjects } from './ActiveProjects';
import { Services } from '../Services';
import { ActiveRequests } from './ActiveRequests';
import { Systems } from './Systems';
import { useCompany } from '../../apiHooks/useCompany';
import { useParams } from 'react-router-dom';

export const Home = () => {
  const { data: currentUser } = useMe();
  const { workspaceSlug } = useParams();

  const { data: company } = useCompany(workspaceSlug ?? '');

  const isRequestsEnabled = company?.settings?.features?.requests;

  const { isLoading } = useProjects();
  return (
    <Container>
      <H2>Welcome, {currentUser?.me?.name}! 👋</H2>

      {!isLoading && (
        <Content>
          <AppointmentMap />
          <Services hideIfEmpty />
          <ActiveProjects />
          {isRequestsEnabled && <ActiveRequests />}
          <Systems />
          {isRequestsEnabled && <ReferralWidget />}
          <Reviews />
        </Content>
      )}
    </Container>
  );
};
