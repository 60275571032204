import React from 'react';
import { useMe } from '../../../apiHooks/useMe';
import { System, SystemPlaceholder } from '../../../kit/components/System';
import { useCompany } from '../../../apiHooks/useCompany';
import { useParams } from 'react-router-dom';

export const Systems = () => {
  const { data: me } = useMe();
  const { workspaceSlug } = useParams();

  const { data: company } = useCompany(workspaceSlug ?? '');

  if (!me || !company) {
    return null;
  }

  if (!company.settings?.features?.fleet) {
    return null;
  }

  if (!me.systems?.length) {
    return <SystemPlaceholder />;
  }

  return (
    <>
      {me.systems.map((system) => (
        <System key={system.id} system={system} />
      ))}
    </>
  );
};
