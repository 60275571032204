export type FormValues = {
  contactName?: string;
  primaryEmail?: string;
  primaryPhone?: string;
  description: string;
  address: string;
  additional?: Record<string, any>;
};

export enum PropertyType {
  Dropdown = 'DROPDOWN',
  Numeric = 'NUMERIC',
  Date = 'DATE',
  Text = 'TEXT',
  Link = 'LINK',
  File = 'FILE',
}
